//@ts-check
// import { Divider } from "antd"
import { graphql, Link } from "gatsby"
import React from "react"
import { CustomBloc } from "../components/CustomBloc"
import SEO from "../components/seo"
import { Title } from "../components/Title"
import { ImageTitleDesc } from "../components/ImageTitleDesc"
import { getTheme } from "../theme"
import { Date } from 'prismic-reactjs'
import { Card } from "antd"
import { Flex } from "../components/Flex"


const AboutUs = ({ data }) => {
  const items = data.allPrismicAboutUs.edges[0].node.data.body[0].items
  const title = data.allPrismicAboutUs.edges[0].node.data.title[0].text
  return (
    <div style={{
      background: getTheme().primaryBackgroundColor07,
    }}>
      {/* <Divider></Divider> */}
      < SEO title={title} />
      <CustomBloc contentStyle={{ justifyContent: 'center' }} >
        <div>
          <Title>{title}</Title>
          <Flex style={{ flexWrap: 'wrap' }}>

            {items
              .sort((a, b) => Date(b.date_article).getTime() - Date(a.date_article).getTime())
              .map((item) => {
                const dateArticle = item.date_article ? Date(item.date_article).toLocaleDateString() : ''
                const titleArticle = item.titre_article[0].text
                const magazineName = item.nom_magazine[0].text
                const magazineCouvertureUrl = item.magazine_couverture.url
                const mediaDocUrl = item.media_document.url
                const Container = mediaDocUrl ? Link : "div"
                return (
                  <Card
                    key={`${magazineName} - ${titleArticle} - ${item.date_article}`}
                    hoverable={false}
                    bordered={false}
                    style={{
                      margin: 10,
                      minWidth: '320px',
                      width: '30%',
                      background: getTheme().primaryBackgroundColor07,
                    }}
                  >
                    <Container to={mediaDocUrl} target={'_blank'}>
                      <ImageTitleDesc
                        descriptionStyle={{ minHeight: 'unset' }}
                        title={`${titleArticle}`}
                        description={`${magazineName} - ${dateArticle}`}
                        src={magazineCouvertureUrl} // Fluid not available. why?
                        // @ts-ignore
                        imageHeight={220}
                        // @ts-ignore
                        imageWidth={220}
                      />
                    </Container>
                  </Card>
                )
              })}

          </Flex>

        </div>
      </CustomBloc>
    </div >

  )
}

export default AboutUs

export const query = graphql`
query AboutUsQuery {
  allPrismicAboutUs {
    edges {
      node {
        data {
          body {
            items {
              date_article
              magazine_couverture {
                url
              }
              nom_magazine {
                text
              }
              titre_article {
                text
              }
              media_document {
                url
              }
            }
          }
          title {
            text
          }
        }
      }
    }
  }
}
`